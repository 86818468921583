// https://github.com/kirill-konshin/next-redux-wrapper#usage-with-redux-persist

import _ from 'lodash'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import { createBlacklistFilter } from 'redux-persist-transform-filter';

import initialState from './initialState'
import reduxPromise from './reduxPromise'
import { RESET_STORE } from './resetStore'
import { store as appStore } from './modules/app'
import { store as resourcesStore } from './modules/resources'
import { store as newReportStore, middleware as newReportMiddleware } from './modules/newReport'
import { middleware as authMiddleware } from './modules/auth'
import { store as tempUserDetailsStore } from './modules/tempUserDetails'
import { store as authStore } from './modules/auth'
import { store as themeStore } from './modules/theme'
import { store as configStore } from './modules/config'
import { store as organisationDetailsStore } from './modules/organisationDetails'
import { store as reportsStore, middleware as reportsMiddleware } from './modules/reports'
import { store as newsStore, middleware as newsMiddleware } from './modules/news'
import { store as newsSdkStore, middleware as newsSdkMiddleware } from './modules/newsSdk'
import { store as notificationsStore } from './modules/notifications'
import { store as followUpsStore } from './modules/followUps'
import { store as formViewsStore } from './modules/formViews'
import { store as platformStore } from './modules/platform'
import { store as drugsStore } from './modules/drugs'
import { store as idapsStore } from './modules/idaps'
import { store as reportConfigurationVersionsStore } from './modules/reportConfigurationVersions'

const combinedReducers = combineReducers({
  app: appStore,
  auth: authStore,
  resources: resourcesStore,
  newReport: newReportStore,
  organisationDetails: organisationDetailsStore,
  tempUserDetails: tempUserDetailsStore,
  reports: reportsStore,
  followUps: followUpsStore,
  news: newsStore,
  newsSdk: newsSdkStore,
  notifications: notificationsStore,
  theme: themeStore,
  config: configStore,
  formViews: formViewsStore,
  platform: platformStore,
  drugs: drugsStore,
  idaps: idapsStore,
  reportConfigurationVersions: reportConfigurationVersionsStore
})

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    return initialState
  }
  return combinedReducers(state, action)
}

const middlewares = [
  // add middlewares
  newReportMiddleware.resetInitialValuesOnLogout,
  reportsMiddleware.resetInitialValuesOnLogout,
  newsMiddleware.resetInitialValuesOnLogout,
  newsSdkMiddleware.resetInitialValuesOnLogout,
  authMiddleware.restoreCredential,
  authMiddleware.editWatchlistToast
]

const subscribers = [
  // add subscribers
]

const registerSubscribers = (store) => {
  _.forEach(subscribers, (subscriber) => {
    const { getState, dispatch } = store
    subscriber({ getState, dispatch })
  })
}

/**
  * @param {object} initialState The store's initial state (on the client side, the state of the server-side store is passed here)
  * @param {boolean} options.isServer Indicates whether makeStore is executed on the server or the client side
  * @param {Request} options.req Node.js `Request` object (only set before `getInitialProps` on the server side)
  * @param {Response} options.res Node.js `Response` object (only set before `getInitialProps` on the server side)
  * @param {boolean} options.debug User-defined debug flag
  * @param {string} options.storeKey The key that will be used to persist the store in the browser's `window` object for safe HMR
  */
export default (nextState, { isServer, req, debug, storeKey, ...options }) => {
  const storeMiddlewares = [
    reduxPromise,
    thunkMiddleware,
    ...middlewares
    // logger
  ]

  if (isServer) {
    return createStore(
      rootReducer,
      nextState || initialState,
      applyMiddleware(
        ...storeMiddlewares
      )
    )
  }

  const storage = require('redux-persist/lib/storage').default
  
  const saveSubsetBlacklistFilter = createBlacklistFilter(
    'auth',
    ['restoreStatus']
  );


  const persistConfig = {
    key: 'primary',
    storage: storage,
    whitelist: ['auth', 'newReport', 'organisationDetails'],
    transforms: [saveSubsetBlacklistFilter]
  }

  const store = createStore(
    persistReducer(persistConfig, rootReducer),
    nextState || initialState,
    composeWithDevTools(
      applyMiddleware(
        ...storeMiddlewares
      )
    )
  )

  store.__persistor = persistStore(store)
  registerSubscribers(store)

  return store
}

