import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  results: [],
  count: null,
  status: undefined,
  currentDrug: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_IDAPS:
      return reducers.fetchIdaps(state, action)
    case constants.FETCH_IDAPS_DRUG_BY_NAME:
      return reducers.fetchIdapsDrugByName(state, action)
    default:
      return state
  }
}
