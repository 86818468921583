import sdk from 'services/vigilanceHubService'
import _ from 'lodash'
import * as constants from './constants'

class IdapsActions {
  fetchIdaps = ({provider}) => ({
    type: constants.FETCH_IDAPS,
    promise: (dispatch, getState) => {
      return sdk.drugs.fetchIdaps({provider})
    }
  })
  fetchIdapsDrugByName = ({provider, drugName}) => ({
    type: constants.FETCH_IDAPS_DRUG_BY_NAME,
    promise: (dispatch, getState) => {
      return sdk.drugs.fetchIdapsDrugByName({provider, drugName})
    }
  })


}

export default new IdapsActions()