import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'
import _ from 'lodash'

class DrugsReducers {
  fetchIdaps (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          results: action.result,
          count: action.result.length,
          error: {}
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

  fetchIdapsDrugByName (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          currentDrug: action.result,
          error: {}
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }

  
}

export default new DrugsReducers()
